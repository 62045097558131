import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import classes from "./App.module.scss";
import DetailsPage from "./Pages/DetailsPage";
import MainPage from "./Pages/MainPage";
import NotFound from "./Pages/NotFoundPage";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className={classes.App}>
          <header className={classes["App-header"]}>
            <h1>French geo location</h1>
          </header>
          <div className={classes.mainContent}>
            <Routes>
              <Route path="/" element={<Navigate to="/addresses" />}></Route>
              <Route path="/addresses" element={<MainPage />} />
              <Route path="/addresses/:label" element={<DetailsPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
