import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Feature } from "@rael06/geoapp-types";
import classes from "./index.module.scss";

export default function AddressCard({ feature }: { feature: Feature }) {
  const { label } = feature.properties;

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <RouterLink to={label} style={{ textDecoration: "none" }}>
          <div className={classes.title}>{label}</div>
        </RouterLink>
      </div>
    </div>
  );
}
