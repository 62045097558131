import * as React from "react";
import Button from "@material-ui/core/Button";
import classes from "./index.module.scss";
import {
  searchedApiUriToText,
  searchedTextToApiUri,
} from "../../../../helpers/parseSearch";
import { CircularProgress } from "@material-ui/core";
import { useSearchedAddresses } from "../../../../helpers/queries";
import { useLocation, useNavigate } from "react-router-dom";

export default function SearchForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = React.useState(
    searchedApiUriToText(location.search)
  );
  const { isLoading } = useSearchedAddresses();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async () => {
    if (search) {
      navigate(`?search=${searchedTextToApiUri(search)}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.field}>
          <div className={classes.title}>
            Enter a <span style={{ fontWeight: "bold" }}>French</span> address
            or latitude,longitude coordinates (i.e: avenue des Champs Elysées OR
            48.871285,2.302859)
          </div>
          <input
            className={classes.input}
            type="text"
            onChange={handleChange}
            value={search}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={classes.actions}>
          <Button size="small" variant="outlined" onClick={handleSubmit}>
            {isLoading ? <CircularProgress /> : "Search"}
          </Button>
        </div>
      </div>
    </div>
  );
}
