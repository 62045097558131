const latLonInSearchedTextRegExp = /^(\d+\.?\d*),(\d+\.?\d*)$/;
export const latLonInApiUriRegExp = /^lat=(\d+\.?\d*)&lon=(\d+\.?\d*)$/;
export const searchUriRegExp = /^\?search=(.+)$/;

export function searchedTextToApiUri(search: string): string {
  if (latLonInSearchedTextRegExp.test(search)) {
    const parse = latLonInSearchedTextRegExp.exec(search);
    const lat = parse && parse[1] && Number(parse[1]);
    const lon = parse && parse[2] && Number(parse[2]);
    return `lat=${lat}&lon=${lon}`;
  }

  return search.trim().replaceAll(" ", "+");
}

export function searchedApiUriToText(searchUri: string): string {
  const matcher = searchUriRegExp.exec(searchUri);
  const search = (matcher && matcher[1]) || "";
  if (latLonInApiUriRegExp.test(search)) {
    const parse = latLonInApiUriRegExp.exec(search);
    const lat = parse && Number(parse[1]);
    const lon = parse && Number(parse[2]);
    return `${lat},${lon}`;
  }

  return search.replaceAll("+", " ").trim();
}
