import { Button, CircularProgress, List, ListItem } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import { useSearchedAddress } from "../../helpers/queries";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import classes from "./index.module.scss";
import { LatLngTuple } from "leaflet";

export default function DetailsPage() {
  const navigate = useNavigate();
  const { searchedAddress } = useSearchedAddress();
  const { geometry, properties } = { ...searchedAddress };
  const [lon, lat] = geometry?.coordinates || [];
  const { housenumber, street, postcode, city } = {
    ...properties,
  };
  const coordinates: LatLngTuple = [lat, lon];

  return (
    <div className={classes.wrapper}>
      <h3>
        Details of address:{" "}
        <span className={classes.mainLabel}>{properties?.label}</span>
      </h3>

      <div className={classes.content}>
        {searchedAddress ? (
          <List>
            {lat && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>Latitude: </span>
                <span className={classes.value}>{lat}</span>
              </ListItem>
            )}
            {lon && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>Longitude: </span>
                <span className={classes.value}>{lon}</span>
              </ListItem>
            )}
            {lat && lon && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>Coordinates: </span>
                <span className={classes.value}>
                  [{lat},{lon}]
                </span>
              </ListItem>
            )}
            {housenumber && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>Number: </span>
                <span className={classes.value}>{housenumber}</span>
              </ListItem>
            )}
            {street && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>Street: </span>
                <span className={classes.value}>{street}</span>
              </ListItem>
            )}
            {postcode && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>ZipCode: </span>
                <span className={classes.value}>{postcode}</span>
              </ListItem>
            )}
            {city && (
              <ListItem classes={{ root: classes.detail }}>
                <span className={classes.label}>City: </span>
                <span className={classes.value}>{city}</span>
              </ListItem>
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
      <div className={classes.map}>
        {lat && lon && (
          <MapContainer
            style={{ width: 500, height: 300 }}
            center={coordinates}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={coordinates}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        )}
      </div>
      <div className={classes.backButton}>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Retour
        </Button>
      </div>
    </div>
  );
}
