import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { SearchResponse } from "@rael06/geoapp-types";
import { latLonInApiUriRegExp, searchUriRegExp } from "./parseSearch";
import { request } from "./request";
import { getLastUrlSegment } from "./urls";

export function useSearchedAddresses() {
  const location = useLocation();

  const searchParam = React.useMemo(() => {
    const matcher = searchUriRegExp.exec(location.search);
    return (matcher && matcher[1]) || "";
  }, [location.search]);
  const queryKey = ["searchedAddresses", searchParam];

  const query = useQuery<SearchResponse | null, Error>(queryKey, () => {
    if (searchParam) {
      return request<SearchResponse>(
        `${
          latLonInApiUriRegExp.test(searchParam) ? "reverse/?" : "search/?q="
        }${searchParam}&limit=100`
      );
    }

    return null;
  });

  return { ...query, searchedAddresses: query?.data?.features || [] };
}

export function useSearchedAddress() {
  const location = useLocation();
  const searchParam = React.useMemo(
    () => getLastUrlSegment(location.pathname),
    [location.pathname]
  );
  const queryKey = ["searchedAddress", searchParam];

  const query = useQuery<SearchResponse, Error>(queryKey, () =>
    request<SearchResponse>(`search/?q=${searchParam}&limit=1`)
  );

  return { ...query, searchedAddress: query.data?.features[0] };
}
