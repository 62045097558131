const apiBaseUrl = "https://api-adresse.data.gouv.fr";

function getDefaultConfig() {
  const defaultConfig = {
    method: "GET",
    headers: getHeaders(),
  };
  return defaultConfig;
}

function getHeaders() {
  return new Headers({
    "Content-Type": "application/json",
  });
}

export async function request<T>(url: string, config = {}): Promise<T> {
  return fetch(`${apiBaseUrl}/${url}`, {
    ...getDefaultConfig(),
    ...config,
  }).then(async (res) => {
    if (res.status === 200 || res.status === 201) return res.json();
  });
}
