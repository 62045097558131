import React from "react";
import { useSearchedAddresses } from "../../helpers/queries";
import AddressCard from "./components/AddressCard";
import SearchForm from "./components/SearchForm";
import classes from "./index.module.scss";

export default function MainPage() {
  const { searchedAddresses } = useSearchedAddresses();

  return (
    <div className={classes.mainContent}>
      <div className={classes.form}>
        <SearchForm />
      </div>
      {searchedAddresses?.map((feature) => {
        return (
          <div key={feature.properties.label}>
            <AddressCard feature={feature} />
          </div>
        );
      })}
    </div>
  );
}
